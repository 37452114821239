import { Search, Icon, Swipe, SwipeItem, Tag } from 'vant'
import Login from '../../intercept/Login.vue'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Navbar: Navbar,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        [Search.name]: Search,
        [Icon.name]: Icon,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tag.name]: Tag
    },
    data () {
        return {
            loadingShow: false,
            indexCode: '',
            article: {},
            informList: [],
            recruitList: []
        }
    },
    mounted () {
        var query = this.$route.query
        this.indexCode = query.indexCode
        this.retrieveArticle()
        this.retrieveInformList()
        this.retrieveRecruitList()
    },
    methods: {
        async retrieveArticle () {
            var pd = { indexCode: this.indexCode }
            var { data: res } = await this.$http.post(this.BMS_URL + '/content/article/retrieveAPCArticle', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.article = res.data
            }
        },
        async retrieveInformList () {
            var pd = { current: 1, size: 5 }
            var { data: res } = await this.$http.post(this.BMS_URL + '/inform/taskAPC/retrieveDisplayTaskList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.informList = res.data
            }
        },
        async retrieveRecruitList () {
            var pd = { classifyCode: '1', current: 1, size: 5 }
            var { data: res } = await this.$http.post(this.BMS_URL + '/recruit/taskAPC/retrieveTaskList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.recruitList = res.data
            }
        },
        inform () {
            var key = window.const.global.INFORM_HOME_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/inform/home' })
        },
        recruit () {
            var key = window.const.global.RECRUIT_HOME_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/recruit/home' })
        },
        back () {
            var back = window.sessionStorage.getItem(window.const.global.CONTENT_ARTICLE_INDEX_BACK)
            if (back !== undefined && back !== null && back !== '') {
                window.location.href = back
            }
        }
    }
}
