<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <Navbar title="文章内容" type="SMP" :callback="back" />
    <van-swipe class="top" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="img in article.imagePath" :key="img">
        <img class="img" :src="img" />
      </van-swipe-item>
    </van-swipe>
    <div class="title">{{ article.title }}</div>
    <van-row class="operate">
      <van-col :span="8"
        ><van-tag :color="COLOR_M" size="medium"
          >已收藏:{{ article.collectTotal }}</van-tag
        >
      </van-col>
      <van-col :span="8"
        ><van-tag :color="COLOR_S1" size="medium"
          >已点赞:{{ article.praiseTotal }}</van-tag
        >
      </van-col>
      <van-col :span="8"
        ><van-tag :color="COLOR_S2" size="medium"
          >分享:{{ article.shareTotal }}
        </van-tag></van-col
      >
    </van-row>
    <div class="content" v-html="article.body"></div>
    <div class="guide">
      <van-row class="item">
        <van-col :span="4" class="type bgcolor-m">公告</van-col>
        <van-col :span="14" class="text text-short">
          <van-swipe
            class="banner"
            :autoplay="3000"
            :touchable="false"
            :show-indicators="false"
            vertical
          >
            <van-swipe-item v-for="inform in informList" :key="inform.code">
              <div class="text text-short">{{ inform.name }}</div>
            </van-swipe-item>
          </van-swipe>
        </van-col>
        <van-col :span="6" class="btn bgcolor-m" @click="inform"
          >查看更多</van-col
        >
      </van-row>
      <van-row class="item">
        <van-col :span="4" class="type bgcolor-s1">岗位</van-col>
        <van-col :span="14" class="text text-short">
          <van-swipe
            class="banner"
            :autoplay="3000"
            :touchable="false"
            :show-indicators="false"
            vertical
          >
            <van-swipe-item v-for="recruit in recruitList" :key="recruit.code">
              <div class="text text-short">{{ recruit.name }}</div>
            </van-swipe-item>
          </van-swipe>
        </van-col>
        <van-col :span="6" class="btn bgcolor-s1" @click="recruit()"
          >查看更多</van-col
        >
      </van-row>
    </div>
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit
      ref="logVisit"
      module-type="CNT"
      module-label="CNT_ARTICLE_INDEX"
    />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
